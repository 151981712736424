const Contact = (props) => {
  const con = props.langdata;
  const basi = con.address.locations;

  return (

  <div className="contactback">
    <div className="container col-md-12 distancecontact">
      <div className="contacts">

        <p className="dirsectioncontact">{con.form.dirname}</p>
          <div class="">
          <div class="row col-md-12">


            <div class="col-md-4">
              <p className="contactheads">{con.form.phone}</p>
                  <div className="col contacts-item-phone">
                     <img className="contacticons" src="icons/social/cc.svg" alt=""/>  : +90 850 800 00 82
                  </div>

                  <div className="col contacts-item-phone">
                    <img className="contacticons" src="icons/social/phone.svg" alt=""/>  : +90 216 366 00 82
                  </div>

                  <div className="col contacts-item-phone">
                    <img className="contacticons" src="icons/social/fax.svg" alt=""/>  : +90 216 366 00 82
                  </div>

                  <div className="col contacts-item-phone">
                    <img className="contacticons" src="icons/social/telegram.svg" alt=""/> :  +90 552 366 00 88
                  </div>

                  <div className="col contacts-item-phone">
                    <img className="contacticons" src="icons/social/whatsapp.svg" alt=""/> : +90 552 366 00 88
                  </div>
            </div>


            <div class="col-md-4">
              <p className="contactheads">{con.form.email}</p>
                  <div className="col contacts-item">
                  {con.form.info}<br></br> info@acilyolcu.com
                  </div>
                  <div className="col contacts-item">
                  {con.form.emergency}<br></br> acil@acilyolcu.com
                  </div>
                  <div className="col contacts-item">
                  {con.form.headquarters}<br></br>   gm@acilyolcu.com
                  </div>
            </div>

            <div class="col-md-4">
                <p className="contactheads">{con.form.about}</p>
                <div className="col contacts-item">{con.form.abouttext}</div>
                <img className="tursab" src="icons/social/tursab.png" alt=""/>
                <img className="sagb" src="icons/social/sb_en.png" alt=""/>
            </div>

          </div>
        </div>


          <p className="dirsectioncontact">{con.address.addressdir}</p>
          <div class="row col-md-12">
            {basi.map(sss => (
              <div className="col-md-4 servicesection" key={sss.id}>
                    <p className="contactheads">{sss.country}</p>
                    <div className="contacts-item">{sss.location}</div>
              </div>
            ))}
          </div>





      </div>
    </div>
  </div>
  );
}

export default Contact;
