import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Logo from '../components/logo';
import Head from '../components/head';
import Languages from '../components/languages';
import Serviceprocess from '../components/serviceprocess';
import Services from '../components/services';
import Doctors from '../components/doctors';
import Hospitals from '../components/hospitals';
import Social from '../components/social';
import Socialmobile from '../components/socialmobile';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Headfooter from '../components/headfooter';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_ua from '../jsons/services/s_ua';
import doc_ua from '../jsons/doctors/doc_ua';
import hos_ua from '../jsons/hospitals/h_ua';
import sp_ua from '../jsons/sprocess/sp_ua';
import head_data_ua from '../jsons/head/head_data_ua';
import con_ua from '../jsons/contacts/con_ua';



//

class Russian extends React.Component {

  render(){
    return (
          <div>
               <Route exact path="/ua" component={() => <Social />}/>
               <Route exact path="/ua" component={() => <Socialmobile />}/>
               <Route exact path="/ua" component={() => <Headfooter />}/>
               <Route exact path="/ua" component={() => <Languages lang="Український" />} />
               <Route exact path="/ua" component={() => <Logo />}/>
               <Route exact path="/ua" component={() => <Head headdata={head_data_ua} />}/>
               <Route exact path="/ua" component={() => <Serviceprocess sprocess={sp_ua} dirname="Наші послуги"/>}/>
               <Route exact path="/ua" component={() => <Services langdata={ser_ua} dirname="Області медичних послуг"/>} />
               <Route exact path="/ua" component={() => <Doctors langdata={doc_ua} dirname="Doxtorlar" />} />
               <Route exact path="/ua" component={() => <Hospitals langdata={hos_ua} />} />
               <Route exact path="/ua" component={() => <Contact langdata={con_ua}/>} />
               <Route exact path="/ua" component={() => <Footer footerdata="Всі права захищені" />} />

          </div>

    );
    }
}

export default Russian;
