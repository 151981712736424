const hospitals_az = [
  {
    id:"0",
    dirname:"Xəstəxanalar",
    endslogan:"Sağlamlıq turizmi sertifikatı olan digər xəstəxanalar da sizinlədir.",
    hospitals:[
      {id:"0",logo:"hlogos/avicennai.png",name:"Avicenna"},
      {id:"1",logo:"hlogos/avicenna.png",name:"Avicenna"},
      {id:"2",logo:"hlogos/memorial.png",name:"Memorial"},
      {id:"3",logo:"hlogos/medicana.svg",name:"Medicana"},
      {id:"4",logo:"hlogos/medical_park.svg",name:"Medical Park"},
      {id:"5",logo:"hlogos/medipol.png",name:"Medipol"},
      {id:"6",logo:"hlogos/lokman.png",name:"Lokman Hekim"},
      {id:"7",logo:"hlogos/mevsim.png",name:"Mevsim dis"}
    ]
  }

]
export default hospitals_az;
