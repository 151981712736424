const con_en = {

  form:
      {
        dirname: "Contacts",
        email: "Emails : ",
        phone:"Phones :",
        emergency:"Emergency : ",
        info:"For informations :",
        headquarters:"Headquarters :",
        about:"About us :",
        abouttext:"Officially licensed and authorized by the Ministry of Health and the Ministry of Culture and Tourism of the Republic of Turkey."

      },
  address:
        {
          addressdir: "Addresses :",
          locations:[
            {id:"1",country:"Turkey :", location:"İçmeler Mah. Altunay Sk. No: 33, İç kapı No: 33. Tuzla, İstanbul, Türkiye."},
            {id:"2",country:"Azerbaijan :", location:"Coming soon."},
            {id:"3",country:"Uzbekistan :", location:"Coming soon."}
          ]
      }
}

export default con_en;
