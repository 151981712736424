const head_data_ua = {

  "form":
      {
        "head": "Позвонить вам ?",
        "button": "Звоните !",
        "phname": "Имя фамилия",
        "phtel": "Номер телефона"
      },
  "head":
        {
        "slogan": "ВАШЕ ЗДОРОВ'Я ДУЖЕ ВАЖЛИВО ДЛЯ НАС",
        "slogantext": "Протягом усього процесу, від початку до кінця, ми завжди поруч із вами."
      },
 "social":
     {
       "first": "Швидкий зв'язок",
       "second": "Можете зв'язатися з нами у будь-який час 24/7"
     }
}

export default head_data_ua;
