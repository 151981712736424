import {Link} from "react-router-dom";

import React, {Component} from 'react';

class Languages extends Component {

    //
    // state = {
    //   isOpen: false
    // };
    // toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    //
    // const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;


    // flex-row-reverse

    render(){

      if (this.props.lang === "English") {
          var one_name = "Türkçe";
          var one_res = "/tr";
          var two_name = "Azərbaycan";
          var two_res = "/az";
          var three_name = "Український";
          var three_res = "/ua";
          var four_name = "Русский";
          var four_res = "/ru";
      }else if (this.props.lang === "Русский"){
          var one_name = "English";
          var one_res= "/";
          var two_name = "Azərbaycan";
          var two_res = "/az";
          var three_name = "Türkçe";
          var three_res = "/tr";
          var four_name = "Український";
          var four_res = "/ua";
      }else if (this.props.lang === "Azərbaycan"){
          var one_name = "English";
          var one_res = "/";
          var two_name = "Türkçe";
          var two_res = "/tr";
          var three_name = "Український";
          var three_res = "/ua";
          var four_name = "Русский";
          var four_res = "/ru";
      }else if (this.props.lang === "Український"){
          var one_name = "English";
          var one_res = "/";
          var two_name = "Türkçe";
          var two_res = "/tr";
          var three_name = "Azərbaycan";
          var three_res = "/az";
          var four_name = "Русский";
          var four_res = "/ru";
      }else{
          var one_name = "English";
          var one_res = "/";
          var two_name = "Azərbaycan";
          var two_res = "/az";
          var three_name = "Український";
          var three_res = "/ua";
          var four_name = "Русский";
          var four_res = "/ru";

      }
            return (
                <div className="container d-flex flex-row-reverse">
                    <div className="dropdown">
                        <button className="dropbtn" onClick={this.toggleOpen} type="button">{this.props.lang}</button>
                            <div className="dropdown-content">
                            <Link to={one_res}>{one_name}</Link>
                            <Link to={two_res}>{two_name}</Link>
                            <Link to={three_res}>{three_name}</Link>
                            <Link to={four_res}>{four_name}</Link>
                            </div>
                    </div>
                </div>
            );
    }
}

export default Languages;
