const ser_ru = [
  {id:"1",logo:"icons/heart.svg",name:"Сердце", text:"Аритмии, сосудистые заболевания, ЭКГ, открытая хирургия, эндоскопия, врожденные болезни сердца, нарушения ритма и проводимости, кардиология и др."},
  {id:"2",logo:"icons/tube_baby.svg",name:"ЭКО", text:"Сбор яйцеклеток, перенос эмбрионов, поддерживающее лечение, хирургические вмешательства повышающие вероятность беременности, детокс спермы, аппликации micro-tese pesa tesa и др."},
  {id:"3",logo:"icons/inner.svg",name:"Внутренние болезни", text:"Гастроэнтерология, гематология, уменьшение желудка, ревматология, медицинская онкология, рак желудка, эндокринология, нарушения обмена веществ и др."},
  {id:"4",logo:"icons/nose.svg",name:"Эстетика и Пластика", text:"Эстетическая хирургия, расщелина губы и неба, врожденные аномалии, операции по подтяжке живота и лица, хирургия половых органов, увеличение, уменьшение и подтяжка груди, травмы всего тела, носа и др."},
  {id:"5",logo:"icons/brain.svg",name:"Мозг", text:"Неврология, нейрорадиология, нейроанестезия, психиатрия, нейроэндоскопия и лечение гидроцефалии, нейрохирургия, болезни дисков и дегенеративные заболевания позвоночника, травмы, хирургия периферических нервов, детская нейрохирургия и др."},
  {id:"6",logo:"icons/hand.svg",name:"Кожа", text:"Акне, микоз, афтозная язва, уплотнение и утолщение, гонорея, болезнь бехчета, бихэктомия, рак кожи, проказа, родинка, экзема, клеточная терапия, остроконечные кондиломы, морщины, бородавки и др."},
  {id:"7",logo:"icons/liver.svg",name:"Печень", text:"Гепатит (A, B, C, D, E), ожирение печени (алкогольное и независимое), цирроз, рак печени, кисты печени; простая киста, эхинококковая киста, гемангиома и др."},
  {id:"8",logo:"icons/stomach.svg",name:"Желудок", text:"Рефлюкс (гастроэзофагеальный рефлюкс), гастрит, язва желудка (язва гастрита), рак желудка, хеликобактер, операция по уменьшению желудка, желудочная грыжа и др."},
  {id:"9",logo:"icons/tooth.svg",name:"Рот и зубы", text:"Челюстно-лицевая хирургия, эндодонтия, эстетическая стоматология, оральная имплантология, ортодонтия, протезирование, пародонтохоли, педодонтия, блок височно-нижнечелюстного сустава и др."},
  {id:"10",logo:"icons/eye.svg",name:"Глаза", text:"Глаукома, окулопластическая хирургия, заболевания сетчатки, рефракционная хирургия, роговица и контактные линзы, косоглазие, амблиопия (ленивый глаз), лазерная терапия, катаракта и др."},
  {id:"11",logo:"icons/compass.svg",name:"Трансплантация органов", text:"Почки, печень, сердце, сердечные клапаны, нервы, сосуды, легкие, сухожилия, роговица, кожа, поджелудочная железа, тонкий кишечник, костный мозг, кости и др."},
  {id:"12",logo:"icons/microscope.svg",name:"Лаборатории", text:"Серология, эндокринология, молекулярная биология, микробиология, клиническая химия, гематология, общие анализы, определение результатов, обследование болезней и др."},
  {id:"13",logo:"icons/hair.svg",name:"Пересадка волос", text:"Трансплантация волос - это естественное и постоянное решение проблемы истончения и облысения у людей, страдающих облысением. Осуществляется путем трансплантации волосяных фолликулов микрохирургическими методами."},
  {id:"14",logo:"icons/ruler.svg",name:"Увеличение роста", text:"Это ортопедическая хирургическая процедура для увеличения длины ног из-за проблем со здоровьем или по эстетическим причинам. Это операция, которая применяется не только к ногам, но и к рукам."},
  {id:"15",logo:"icons/gynecologist.svg",name:"Гинекология и Онкология", text:"Акушерство, гинекологические заболевания, менопауза, миома, поликистоз яичников, рак шейки матки, рак эндометрия, опухоли прямой кишки, рак яичников и др."},
  {id:"16",logo:"icons/bone.svg",name:"Кости", text:"Хирургия опухолей костей и мягких тканей, трансплантация костного мозга (взрослый и детский), заболевания костей, переломы, хирургическое вмешательство и др."},
  {id:"17",logo:"icons/baby-trolley.svg",name:"Педиатрия и детские болезни", text:"Аллергия, хирургия, нейрохирургия, онкология, гематология, кардиология, неврология, нефрология, инфекции, гастроэнтерология, генетические заболевания, интенсивная терапия, психическое здоровье, урология, ревматология и др."},
  {id:"18",logo:"icons/lungs.svg",name:"Заболевания грудной клетки", text:"Астма, аллергические заболевания, бронхит, хроническая обструктивная болезнь, легочные заболевания, пневмония, туберкулез, тромбоэмболия легочной артерии, хронический кашель, рак легких и др."},
  {id:"19",logo:"icons/gym.svg",name:"Физиотерапия и реабилитация", text:"Физиотерапия и реабилитация, это направление в медицине, целью которой является повышение до максимального уровня функций тела, восстановление работоспособности, улучшение качества жизни людей утративших функциональность и подвижность тела."},
  {id:"20",logo:"icons/operation.svg",name:"Хирургия", text:"Мозг и нервы, общая хирургия, зубы, челюсть, кисть, эстетическая, пластическая и реконструктивная, грудная, гастроэнтерология и колопроктология, сердечно-сосудистая, роботизированная хирургия, протезы стопы и голеностопного сустава, бедра и колена  и др."},
  {id:"21",logo:"icons/stethoscope.svg",name:"Другие", text:"В нашем списке категорий представлены не все зоны медицинских услуг предоставляемые больницами. Свяжитесь с нами, чтобы узнать о существующих услугах и решениях, которых нет в списке."}
]
export default ser_ru;
