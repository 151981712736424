const con_az = {

  form:
      {
        dirname: "İletişim bilgileri",
        email: "Emailler : ",
        phone:"Telefonlar :",
        emergency:"Acil durum : ",
        info:"Genel bilgi :",
        headquarters:"Genel müdürlük :",
        about:"Hakkımızda :",
        abouttext:"T.C. Kültür ve Turizm Bakanlığı tarafından 'A grubu Seyahat Acentası İşletme Belgesi' ve T.C. Sağlık Bakanlığın tarafından 'Uluslararası Sağlık Turizmi Yetki Belgesi' belgelerini ile yetkiliz."

      },
  address:
        {
          addressdir: "Adresler :",
          locations:[
            {id:"1",country:"Türkiye :", location:"İçmeler Mah. Altunay Sk. No: 33, İç kapı No: 33. Tuzla, İstanbul, Türkiye."},
            {id:"2",country:"Azerbaycan :", location:"Çok yakında."},
            {id:"3",country:"Özbekistan :", location:"Çok yakında."}
          ]
      }
}

export default con_az;
