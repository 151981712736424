const Headfooter =(props) => {
  return (
      <div className="col-md-12">
        <div className="headfooter">
          <div class="container">
            <div class="row">

              <div class="col-6 "><img className="miniicons" src='icons/social/phone.svg' alt=""/>+90 850 800 00 82</div>
              <div class="col-6"><img className="miniicons" src='icons/social/envelopemini.svg' alt=""/>info@acilyolcu.com</div>

            </div>
          </div>
        </div>
      </div>
  );
}
export default Headfooter;
