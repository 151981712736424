const sp_ua = [
   {id:"1",logo:"icons/medical-sign.svg",name:"Попередні процедури", text:"Звертаючись до нас з-за кордону, ми надсилаємо інформацію про ваш стан здоров'я, звіти та аналізи до лікарень , дізнаємося попередній діагноз лікарів та приблизні ціни і передаємо їх вам."},
   {id:"2",logo:"icons/doctor.svg",name:"Доктора", text:"До ваших послуг професійні лікарі та сотні медичних працівників. Ми відбираємо найкращих лікарів, які є фахівцями саме в галузі вашої проблеми, і пов'язуємо їх з вами."},
   {id:"3",logo:"icons/hospital2.svg",name:"Лікарні", text:"Ми об'єднуємо лікарні Турецької Республіки з якими у нас є угода, що відповідає всім міжнародним стандартам. Ми можемо підібрати для вас лікарні різного рівня на ваш запит."},
   {id:"4",logo:"icons/ticket.svg",name:"Квиток літака", text:"Ми пропонуємо вам авіаквитки до Туреччини і назад з будь-якої країни за доступними цінами та зі знижкою від партнерських авіакомпаній."} ,
   {id:"5",logo:"icons/van.svg",name:"Транспортування", text:"Під час вашого прибуття ми зустрінемо вас в аеропорту на наших віп-автомобілях і доставимо прямо до лікарні або у ваш готель, і по дорозі назад до вашої країни ми так само доставимо вас в аеропорт на віп-автомобілі."},
   {id:"6",logo:"icons/flight.svg",name:"Літак швидкої допомоги", text:"У дуже термінових випадках ми негайно доставимо вас з будь-якої країни до Туреччини приватним літаком швидкої допомоги. Разом з літаком також прибуде приватна медична бригада, що складається з лікарів."},
   {id:"7",logo:"icons/ambulance.svg",name:"Машина швидкої допомоги", text:"В екстрених випадках ми особисто зустрічаємо пацієнтів з аеропорту на машині швидкої допомоги і доставляємо їх прямо до лікарні. Для нас важлива кожна хвилина вашого здоров'я."},
   {id:"8",logo:"icons/hotel.svg",name:"Готели", text:"Ми підбираємо для вас готелі різних категорій придатні до ваших уподобань за знижковими цінами, і заселяємо вас в готелі, що знаходяться неподалік вашої лікарні."},
   {id:"9",logo:"icons/boy.svg",name:"Перекладач", text:"Ми пропонуємо професійних перекладачів, які спростять вам спілкування з лікарями та виконають переклад з вашої рідної мови Турецькою мовою. Усуваємо мовні бар'єри."},
   {id:"10",logo:"icons/papers.svg",name:"Переклад документів", text:"Перекладаємо ваші медичні звіти, файли та інші інформаційні документи з вашої рідної мови Турецькою мовою. Надаємо їх лікарням та лікарям, сприяємо прискоренню часу для визначення правильного діагнозу."}
]
export default sp_ua;
