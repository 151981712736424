const con_tr = {

  form:
      {
        dirname: "Контакты",
        email: "Емейлы : ",
        phone:"Телефоны :",
        emergency:"Чрезвычайная ситуация : ",
        info:"Для получения информации :",
        headquarters:"Штаб-квартира :",
        about:"Про нас :",
        abouttext:"Официально лицензированы и уполномочены Министерством Здравоохранения и Министерством Культуры и Туризма Турецкой Республики."

      },
  address:
        {
          addressdir: "Адреса :",
          locations:[
            {id:"1",country:"Турция :", location:"İçmeler Mah. Altunay Sk. No: 33, İç kapı No: 33. Tuzla, İstanbul, Türkiye."},
            {id:"2",country:"Азербайджан :", location:"В процессе."},
            {id:"3",country:"Узбекистан :", location:"В процессе."}
          ]
      }
}

export default con_tr;
