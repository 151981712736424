const sp_tr = [
  {id:"1",logo:"icons/medical-sign.svg",name:"Ön işlemler", text:"Yurtdışından bizimle iletişime geçerek sizin sağlık durumunuza ait bilgileri raporları ve analizleri hastanelere iletip doktorların ön teşhislerini öğrenip sizlere aktarıyoruz. Ve tedavi görülecek hastaneyi ve doktorları belirleyip siz gelmeden önce tüm işlemleri ayarlıyoruz"},
  {id:"2",logo:"icons/doctor.svg",name:"Doktor", text:"Kıdemli profesör doktorlar ve yüzlerce sağlık personeli hizmetinizdedir. Sizin problenize çözüm olacak doktorları sizler için titiz bir şekilde seçerek sizinle bağlıyoruz."},
  {id:"3",logo:"icons/hospital2.svg",name:"Hastane", text:"Türkiye Cumhuriyeti'nde bulunan ve anlaşmamız olan dünya standarlarına sahip hastaneleri bir araya topluyoruz. Muayine, operasyon, ameliyat v.s. işlemler bizim partner hastaneler tarafından sunuluyor. Bizim önerimiz ve ya sizin tercihiniz ile size uygun hastaneyi sunuyoruz."},
  {id:"4",logo:"icons/ticket.svg",name:"Uçak Bileti", text:"İstenilen ülkeden Türkiye'ye geliş ve dönüş uçak biletlerinizi anlaşmalı olduğumuz havayolları şirketlerinden uygun indirimli fiyatlar ile size sunuyoruz."},
  {id:"5",logo:"icons/van.svg",name:"Ulaşım", text:"Geldiğiniz zaman sizi havalimanında vip araçlarımız ile karşılayıp direk hastaneye ve ya hotelinize ulaşım sağlıyoruz, ve aynı şekilde ülkenize geri dönerken sizi vip araç ile havalimanından ugurluyoruz."},
  {id:"6",logo:"icons/flight.svg",name:"Ambulans Uçak", text:"Çok acil durumlarda istenilen ülkeden Türkiye'ye sizi özel ambulans uçak ile derhal getiriyoruz. Uçak ile beraber doktorlardan oluşan özel sağlık ekibide gelmektedir."},
  {id:"7",logo:"icons/ambulance.svg",name:"Ambulans", text:"Acil durumlarda hastaları bizzat havalimanından Ambulans ile karşılayıp direk hastaneye yetiştiriyoruz. Bizim için sizin sağlığınızda her dakika kritik öneme sahiptir."},
  {id:"8",logo:"icons/hotel.svg",name:"Hotel", text:"Anlaşmalı olduğumuz her kategori otelleri sizlere sunuyoruz, indirimli fiyatlar üzerinden hotelleri sizin tercihinize uygun muayine göreceğiniz hastanelere yakın şekilde listeliyoruz."},
  {id:"9",logo:"icons/papers.svg",name:"Dosya cevirisi", text:"Medikal raporların, dosyaların, size ait bilgilerin, sizin ana dilinizden Türk diline cevirisini yaparak hastanalere ve doktorlara sunarak ön teşhislerin doğru belirlenmesini sağlıyoruz."},
  {id:"10",logo:"icons/boy.svg",name:"Tercüman", text:"Doktorlar ile iletişim kurmanızı kolaylaştıracak, sizin ana dilinizden Türk diline ceviri yapacak profesyönel tercumanları talebiniz geregi sizlere sunuyoruz. Dil engellerini kaldırıyoruz."}
]
export default sp_tr;
