const Social =(props) => {

  function gototel() {
    window.location.href = 'https://t.me/acilyolcu';
  }

  function gotowp() {
    window.location.href = 'https://wa.me/905523660088';
  }

  function gomail() {
    window.location = "mailto:info@acilyolcu.com";
  }

  return (
    <div className="col-md-1">

      <div className="social">
          <div className="col">
              <img className="socialicon" onClick={gototel} src="icons/social/telegram.svg" alt=""/>
          </div>
          <div className="col">
              <img className="socialicon" onClick={gotowp} src="icons/social/whatsapp.svg" alt=""/>
          </div>
          <div className="col">
              <img className="socialicon" onClick={gomail} src="icons/social/envelope.svg" alt=""/>
          </div>
        </div>

    </div>
  );
}
export default Social;
