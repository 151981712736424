const doc_ua = [
  {
    id:"0",
    dirname:"Лікарі",
    endslogan:"І багато інших.",
    doctors:[
      {id:"0",logo:"images/doctors/1.jpg",name:"Проф. Д-р. Халиль Ибрагим Беклер", text:"Мікрохірургія, Ортопедія"},
      {id:"1",logo:"images/doctors/8.jpg",name:"Проф. Д-р. Метин Экинджи", text:"Здоров'я та хвороби очей"},
      {id:"2",logo:"images/doctors/9.jpg",name:"Проф. Д-р. Мехмет Йылдыз", text:"Хірургія"},
      {id:"3",logo:"images/doctors/12.jpg",name:"Проф. Д-р. Ибрагим Этем Пойразоглу", text:"Отоларинголог (ЛОР)"},
      {id:"4",logo:"images/doctors/13.jpg",name:"Доц. Д-р. Оркун Кобан", text:"Хірургія мозку та нервів"},
      {id:"5",logo:"images/doctors/17.jpg",name:"Доц. Д-р. Мухаммед Бешир Озтюрк", text:"Пластична хірургія"},
      {id:"6",logo:"images/doctors/2.jpg",name:"Оп. Д-р. Осман Акташ", text:"Хірургія"},
      {id:"7",logo:"images/doctors/3.jpg",name:"Оп. Д-р. Макбуле Лачинюрт Озкан", text:"Хірургія"},
      {id:"8",logo:"images/doctors/4.jpg",name:"Оп. Д-р. Осман Фазлыогуллары", text:"Серцево-судинна хірургія"},
      {id:"9",logo:"images/doctors/18.jpg",name:"Оп. Д-р. Асуде Кёксал", text:"Пластична хірургія"},
      {id:"10",logo:"images/doctors/16.jpg",name:"Оп. Д-р. Айкут Мысырлыоглу", text:"Пластична хірургія"},
      {id:"11",logo:"images/doctors/5.jpg",name:"Оп. Д-р. Мехмет Акиф Гёхюсгерен", text:"Нейрохірургія"},
      {id:"12",logo:"images/doctors/6.jpg",name:"Оп. Д-р. Тюлин Эроглу Элмас", text:"Гінекологія"},
      {id:"13",logo:"images/doctors/11.jpg",name:"Оп. Д-р. Эндер Кавукчу", text:"Урологія"},
      {id:"14",logo:"images/doctors/7.jpg",name:"Спец. Д-р. Хакки Озаслан", text:"Радіологія"},
      {id:"15",logo:"images/doctors/14.jpg",name:"Спец. Д-р Вусал Ханкишиев", text:"Кардіологія"},
      {id:"16",logo:"images/doctors/10.jpg",name:"Спец. Д-р. Юсуф Дикичи", text:"Неврологія"},
      {id:"17",logo:"images/doctors/15.jpg",name:"Дт. Деха Байрак", text:"Стоматологія"} ]
}
]
export default doc_ua;
