const head_data_tr = {

  "form":
      {
        "head": "Sizi arayalım ?",
        "button": "Arayın !",
        "phname": "İsim soyisim",
        "phtel": "Telefon numaranız"
      },
  "head":
        {
        "slogan": "SAĞLIĞINIZ BİZİM İÇİN ÇOK ÖNEMLİ",
        "slogantext": "Tüm süreç boyunca baştan sonuna kadar sizin yanınızdayız"
      },
 "social":
     {
       "first": "Hızlı iletişim",
       "second": "Bizimle istediğiniz zaman 24/7 iletişime geçebilirsiniz"
     }

}

export default head_data_tr;
