const ser_az = [
  {
    id:"1",
    logo:"icons/heart.svg",
    name:"Ürək",
    text:"Aritmiya, damar xəstəlikləri, EKQ, açıq cərrahiyyə, endoskopiya,anadangəlmə ürək xəstəlikləri, ritm və keçiricilik pozgunluqları, kardiologiya və s."
  },
  {
    id:"2",
    logo:"icons/tube_baby.svg",
    name:"ECO",
    text:"Yumurta toplama, embrion transferi, dəstəkləyici müalicə, hamiləlik ehtimalını artıran cərrahi müdaxilələr, sperma detoksu, mikro tətbiqlər və s."
  },
  {
    id:"3",
    logo:"icons/inner.svg",
    name:"Daxili xəstəliklər",
    text:"Qastroenterologiya, hematologiya, mədə reduksiya, tibbi onkologiya, mədə xərçəngi,endokrinologiya, metobolik pozğunluqlar və s."
  },
  {
    id:"4",
    logo:"icons/nose.svg",
    name:"Estetik ve Plastik",
    text:"Estetik cərrahiyyə, dodaq və damaq yarığı, anadangəlmə anomaliyalar, qarın gərmə və üz gərmə əməliyyatları, cinsiyyət orqan cərrahiyyəsi, döş böyütmə, kiçiltmə və qaldırma, bütün bədən travmalari, burun və s."
  },
  {
    id:"5",
    logo:"icons/brain.svg",
    name:"Beyin",
    text:"Nevrologiya, neyroradiologiya, neyroanesteziya, psixiatriya, neyroendoskopiy və hidrosefaliyanın müalicəsi, disk xəstəlikləri və onurğanın degenarativ xəstəlikləri, travma, periferik sinir cərrahiyyəsi, uşaq neyrocərrahiyyəsi və s."
  },
  {
    id:"6",
    logo:"icons/hand.svg",
    name:"Dəri",
    text:"Sizanaq, mikoz, aft yarası, indurasiya və qalınlaşma, behçet xəstəliyi, doğum ləkəsi, dəri xərçəngi, ekzema, hüceyrə terapiyası, genital ziyil, qirişlar ziyillər və s."
  },
  {
    id:"7",
    logo:"icons/liver.svg",
    name:"Qaraciyər",
    text:"Hepatit (A,B,C,D,E), yağlı qaraciyər, siroz,qaraciyər xərçəngi, qaraciyər kistləri: sadə kist,hedatik kist, hemangioma və s."
  },
  {
    id:"8",
    logo:"icons/stomach.svg",
    name:"Mədə",
    text:"Reflü (qastroezofageal reflü) qastrit, mədə xorası (qasrtit xorası), mədə xərçəngi, Helicobacter, mədə kiçiltmə əməliyyatı, mədə yırtığı və s."
  },
  {
    id:"9",
    logo:"icons/tooth.svg",
    name:"Ağız və Dişlər",
    text:"Ağız, diş ve çÜz-çənə cərrahiyyəsi, estetik stomalologiya, ağız implantologiyası, ortodontiya, protezlər, periodontal cərrahiyyə və s."
  },
  {
    id:"10",
    logo:"icons/eye.svg",
    name:"Gözlər",
    text:"Qlaukoma, okuloplastik cərrahiyyə, tor qişa xəstəlikləri,refraktiv cərrahiyyə, buynuz qişa bə kontakt linzalar, çəpgözlük,ambliyopiya (təmbəl göz), lazer terapiya,katarakta və s."
  },
  {
    id:"11",
    logo:"icons/compass.svg",
    name:"Orqan transplantasiyası",
    text:"Böyrək,qaraciyər, ürək,ürək qapaqları, sinirlər, qan damarları, ağciyər, vətərlər, buynuz qişa, dəri, mədəaltı vəzi, nazik bağırsaq, sümük iliyi, sümüklər və s."
  },
  {
    id:"12",
    logo:"icons/microscope.svg",
    name:"Labaratoriyalar",
    text:"Serologiya, endokrinologiya,molekulyar biologiya,mikrobiologiya, kimya, hematologiya, ümumi müayinələr, nəticələrin müəyyən edilməsi,xəstəliklərin müayinəsi və s."
  },
  {
    id:"13",
    logo:"icons/hair.svg",
    name:"Saç transplantasiyası",
    text:"Saç əkimi, saç tökülməsindən əziyyət çəkən insanlarda incəlmə və keçəlləşmənin təbii və qalıcı həlldir. Mikrocərrahi üsullarla saç köklərinin transplantasiyası ilə həyata keçirilir."
  },
  {
    id:"14",
    logo:"icons/ruler.svg",
    name:"Boy uzaltma",
    text:"Bu, sağlamlığ problerlərinə və ya estetik səbəblərə görə ayaqların uzunluğunu artırmaq üçün edilən ortopedik cərrahi əməliyyatdır. Bu təkcə ayaqlara deyil, qollarada tətbiq edilən əməliyyatdır."
  },
  {
    id:"15",
    logo:"icons/gynecologist.svg",
    name:"Ginekologiya və Onkologiya",
    text:"Doğuş, ginekoloji xəstəliklər, menopoz, mioma, polikist yumurtalıq sindromu,uşaqliq boynu xərçəni,endometrium xərçəngi, düz bağırsaq şişləri, yumurtalıq xərçəngi və s."
  },
  {
    id:"16",
    logo:"icons/bone.svg",
    name:"Sümüklər",
    text:"Sümük və yumşaq toxumaların şişlərinin cərrahiyyəsi, sümük vvə iliyin transplantasiyası (böyükər və uşaqlar), sümük xəstəlikləri, sınıqlar, cərrahiyyə və s."
  },
  {
    id:"17",
    logo:"icons/baby-trolley.svg",
    name:"Pediatriya və Uşaq xəstəlikləri",
    text:"Allergiya, cərrahiyyə, neyrocerrahiyyə, onkologiya, hematologiya, kardiologiya, nevro logiya,infeksiyalar, qastreontologiya, genetik xəstəliklər, reanimasiya, psixi sağlamliq, urologiya, revmatologiya və s."
  },
  {
    id:"18",
    logo:"icons/lungs.svg",
    name:"Sinə xəstəlikləri",
    text:"Astma, allergik xəstəliklər, bronxit, xroniki obstruktiv xəstəliklər, ağciyər xəstəlikləri, pnevmaniya, vərəm, agciyər emboliyası, xroniki öskürək, ağciyər xərçəngi və s."
  },
  {
    id:"19",
    logo:"icons/gym.svg",
    name:"Fizioterapiya və Reabilitasiya",
    text:"Fizioterapiya və reabiliasiya tibbdə bir istiqamətdir, məqsədi bədən funksiyalarını maksimum səviyyəyə qaldırmaq, performansını bərpa etmək və bədənin funksionallığını, hərəkkətliyini itirmiş insanların həyat keyfiyyətini yaxşılaşdırmaqdır."
  },
  {
    id:"20",
    logo:"icons/operation.svg",
    name:"Cərrahiyyə",
    text:"Beyin və sinirlər, ümumi cərrahiyyə, dişlər, çənə, əl, estetik plastik ve rekonstruktiv, döş qastreontologiya və koloproktologiya,ürək-damar,robotik cərrahiyyə,protez ayaq və topuq,bud-diz və s."
  },
  {
    id:"21",
    logo:"icons/stethoscope.svg",
    name:"Digər",
    text:"Bizim kateqoriyalar siyahısına xəstəxanalar tərəfindən göstərilən tibbi xidmətin bütün sahələri daxil deyil.Mövcud xidmətlər və siyahıda olmayan xidmətlər haqqinda öyrənmək üçün bizimlə əlaqə saxlayın."
  }
]
export default ser_az;
