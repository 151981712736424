const Serviceprocess =(props) => {

  const spro = props.sprocess;
  return (
    <div className="serviceprocessback">
      <div className="container col-md-12">
        <p className="dirsectionsp">{props.dirname}</p>
        <div className="row services">

              {spro.map(sp => (
                  <div className="col-md-6 servicesection" key={sp.id}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2">
                            <img className="icons" src={sp.logo}alt=""/>
                        </div>
                        <div className="col-md-10">
                              <p className="service-name">{sp.name}</p>
                              <p className="service-desing">{sp.text}</p>
                        </div>
                      </div>
                    </div>
                    <br></br>
                  </div>
                ))}

        </div>
        </div>
  </div>
  );
}
export default Serviceprocess;
