import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Logo from '../components/logo';
import Head from '../components/head';
import Languages from '../components/languages';
import Serviceprocess from '../components/serviceprocess';
import Services from '../components/services';
import Doctors from '../components/doctors';
import Hospitals from '../components/hospitals';
import Social from '../components/social';
import Socialmobile from '../components/socialmobile';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Headfooter from '../components/headfooter';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_en from '../jsons/services/s_en';
import doc_en from '../jsons/doctors/doc_en';
import hos_en from '../jsons/hospitals/h_en';
import sp_en from '../jsons/sprocess/sp_en';
import head_data_en from '../jsons/head/head_data_en';
import con_en from '../jsons/contacts/con_en';

class English extends React.Component {

  render(){
    return (
          <div>
                <Route exact path="/" component={() => <Social />}/>
                <Route exact path="/" component={() => <Socialmobile />}/>
                <Route exact path="/" component={() => <Headfooter />}/>
                <Route exact path="/" component={() => <Languages lang="English"/>} />
                <Route exact path="/" component={() => <Logo />}/>
                <Route exact path="/" component={() => <Head headdata={head_data_en} />}/>
                <Route exact path="/" component={() => <Serviceprocess sprocess={sp_en} dirname="Services"/>}/>
                <Route exact path="/" component={() => <Services langdata={ser_en} dirname="Health service areas"/>} />
                <Route exact path="/" component={() => <Doctors langdata={doc_en} />} />
                <Route exact path="/" component={() => <Hospitals langdata={hos_en} />} />
                <Route exact path="/" component={() => <Contact  langdata={con_en}/>} />
                <Route exact path="/" component={() => <Footer footerdata="All rights reserved" />} />
          </div>

    );
    }
}

export default English;
