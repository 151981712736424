import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Logo from '../components/logo';
import Head from '../components/head';
import Languages from '../components/languages';
import Serviceprocess from '../components/serviceprocess';
import Services from '../components/services';
import Doctors from '../components/doctors';
import Hospitals from '../components/hospitals';
import Social from '../components/social';
import Socialmobile from '../components/socialmobile';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Headfooter from '../components/headfooter';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_az from '../jsons/services/s_az';
import doc_az from '../jsons/doctors/doc_az';
import hos_az from '../jsons/hospitals/h_az';
import sp_az from '../jsons/sprocess/sp_az';
import head_data_az from '../jsons/head/head_data_az';
import con_az from '../jsons/contacts/con_az';

class Azerbaijan extends React.Component {

  render(){
    return (
      <div>
            <Route exact path="/az" component={() => <Social />}/>
            <Route exact path="/az" component={() => <Socialmobile />}/>
            <Route exact path="/az" component={() => <Headfooter />}/>
            <Route exact path="/az" component={() => <Languages lang="Azərbaycan"/>} />
            <Route exact path="/az" component={() => <Logo />}/>
            <Route exact path="/az" component={() => <Head headdata={head_data_az} />}/>
            <Route exact path="/az" component={() => <Serviceprocess sprocess={sp_az} dirname="Xidmətlər"/>}/>
            <Route exact path="/az" component={() => <Services langdata={ser_az} dirname="Səhiyyə xidmət sahələri"/>} />
            <Route exact path="/az" component={() => <Doctors langdata={doc_az} />} />
            <Route exact path="/az" component={() => <Hospitals langdata={hos_az} />} />
            <Route exact path="/az" component={() => <Contact  langdata={con_az}/>} />
            <Route exact path="/az" component={() => <Footer footerdata="Bütün hüquqlar qorunur" />} />
      </div>

    );
    }
}

export default Azerbaijan;
