const Head =(props) => {


  function gototel() {
    window.location.href = 'https://t.me/acilyolcu';
  }

  function gotowp() {
    window.location.href = 'https://wa.me/905523660088';
  }

    return (
      <div className="wavetop">
        <div className="col-md-12 container distancehead">
                  <div className="col-md-8 offset-md-2 sloganxl">{props.headdata.head.slogan}</div>
                  <div className="col-md-8 offset-md-2 slogans">{props.headdata.head.slogantext}</div>

         <div class="container">

                  <div className="col-12 row">
                    <div className="socialslogan">{props.headdata.social.first}</div>
                    <p className="contackwithus_smalltext">{props.headdata.social.second}</p>

                      <div className="col-md-6 contacts-item colright">
                        <button type="button" onClick={gototel} class="socialHeadButton">
                          <img className="socialmobileiconhead" src="icons/social/telegram.svg" alt=""/>
                          &nbsp; Telegram  &nbsp;
                        </button>
                      </div>

                      <div className="col-md-6 contacts-item colleft">
                          <button type="button" onClick={gotowp} class="socialHeadButtonWhatsapp">
                            <img className="socialmobileiconhead" src="icons/social/whatsapp.svg" alt=""/>
                             &nbsp; Whatsapp
                          </button>
                      </div>
                  </div>
         </div>

       </div>
    </div>
    );
  }
  export default Head;
