const ser_en = [
  {id:"1",logo:"icons/heart.svg",name:"Heart", text:"Arrhythmias, vascular diseases, ECG, open surgery, endoscopy, congenital heart diseases, rhythm and conduction disturbances, cardiology, etc."},
  {id:"2",logo:"icons/tube_baby.svg",name:"In vitro fertilization", text:"Egg collection, embryo transfer, supportive care, pregnancy-enhancing surgery, sperm detox, micro-tese pesa tesa applications, etc."},
  {id:"3",logo:"icons/inner.svg",name:"Internal illnesses", text:"Gastroenterology, hematology, stomach reduction, rheumatology, medical oncology, stomach cancer, endocrinology, metabolic disorders, etc."},
  {id:"4",logo:"icons/nose.svg",name:"Aesthetics and Plastic", text:"Aesthetic surgery, cleft lip and palate, congenital anomalies, tummy tuck and face lift surgery, genital surgery, breast lifting reduction and augmentation, full body injuries, nose, etc."},
  {id:"5",logo:"icons/brain.svg",name:"Brain", text:"Neurology, neuroradiology, neuroanesthesia, psychiatry, neuroendoscopy and treatment of hydrocephalus, neurosurgery, disc disease and degenerative diseases of the spine, trauma, peripheral nerve surgery, pediatric neurosurgery, etc."},
  {id:"6",logo:"icons/hand.svg",name:"Skin", text:"Acne, mycosis, aphthous ulcer, induration and thickening, gonorrhea, behcet's disease, bichectomy, skin cancer, leprosy, mole, eczema, cell therapy, genital warts, wrinkles, warts, etc."},
  {id:"7",logo:"icons/liver.svg",name:"Liver", text:"Hepatitis (A, B, C, D, E), fatty liver (alcoholic and independent), cirrhosis, liver cancer, liver cysts; simple cyst, echinococcal cyst, hemangioma, etc."},
  {id:"8",logo:"icons/stomach.svg",name:"Stomach", text:"Reflux (gastroesophageal reflux), gastritis, stomach ulcer (gastritis ulcer), stomach cancer, Helicobacter, stomach reduction surgery, gastric hernia, etc."},
  {id:"9",logo:"icons/tooth.svg",name:"Mouth and teeth", text:"Maxillofacial surgery, endodontics, aesthetic dentistry, oral implantology, orthodontics, prosthetics, periodontal disease, pedodontics, temporomandibular joint block, etc."},
  {id:"10",logo:"icons/eye.svg",name:"Eyes", text:"Glaucoma, oculoplastic surgery, retinal diseases, refractive surgery, cornea and contact lenses, strabismus, amblyopia (lazy eye), laser therapy, cataracts, etc."},
  {id:"11",logo:"icons/compass.svg",name:"Organ transplantation", text:"Kidneys, liver, heart, heart valves, nerves, vessels, lungs, tendons, cornea, skin, pancreas, small intestine, bone marrow, bones, etc."},
  {id:"12",logo:"icons/microscope.svg",name:"Laboratories", text:"Serology, endocrinology, molecular biology, microbiology, clinical chemistry, hematology, general analyzes, determination of results, examination of diseases, etc."},
  {id:"13",logo:"icons/hair.svg",name:"Hair transplantation", text:"Hair transplant is a natural and permanent solution to the problem of thinning and balding in people with baldness. It is carried out by transplanting hair follicles using microsurgical methods."},
  {id:"14",logo:"icons/ruler.svg",name:"Elongation of growth", text:"It is an orthopedic surgical procedure to increase the length of the legs due to health problems or for aesthetic reasons. This is an operation that is applied not only to the legs, but also to the hands."},
  {id:"15",logo:"icons/gynecologist.svg",name:"Gynecology and Oncology", text:"Obstetrics, gynecological diseases, menopause, fibroids, polycystic ovaries, cervical cancer, endometrial cancer, rectal tumors, ovarian cancer, etc."},
  {id:"16",logo:"icons/bone.svg",name:"Bones", text:"Bone and soft tissue tumor surgery, bone marrow transplantation (adults and children), bone diseases, fractures, surgery, etc."},
  {id:"17",logo:"icons/baby-trolley.svg",name:"Pediatrics and childhood illnesses", text:"Allergy, surgery, neurosurgery, oncology, hematology, cardiology, neurology, nephrology, infections, gastroenterology, genetic diseases, intensive care, mental health, urology, rheumatology, etc."},
  {id:"18",logo:"icons/lungs.svg",name:"Chest diseases", text:"Asthma, allergic diseases, bronchitis, chronic obstructive disease, pulmonary diseases, pneumonia, tuberculosis, pulmonary embolism, chronic cough, lung cancer, etc."},
  {id:"19",logo:"icons/gym.svg",name:"Physiotherapy and rehabilitation", text:"Physiotherapy and rehabilitation is a direction in medicine, the purpose of which is to increase body functions to the maximum level, restore working capacity, improve the quality of life of people who have lost their functionality and body mobility."},
  {id:"20",logo:"icons/operation.svg",name:"Surgery", text:"Brain and nerves, general surgery, teeth, jaw, hand, aesthetic, plastic and reconstructive, thoracic, gastroenterology and coloproctology, cardiovascular, robotic surgery, foot and ankle, hip and knee prostheses, etc."},
  {id:"21",logo:"icons/stethoscope.svg",name:"Other", text:"In our category list does not include all of the health care areas provided by hospitals. Contact us to find out about full existing services and solutions that are not listed."}
]
export default ser_en;
