const Logo = () =>{

return (
    <div className="col-md-12 distancelogo">
          <div className="container ">
              <img className="logo" src='images/logo.png' alt=""/>
              <img className="namelogo" src='svg/logoname.png' alt=""/>
          </div>
    </div>
);
}

export default Logo;
