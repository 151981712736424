const con_ua = {

  form:
      {
        dirname: "Контакти",
        email: "Емейли : ",
        phone:"Телефони :",
        emergency:"Надзвичайна ситуація : ",
        info:"Для отримання інформації :",
        headquarters:"Штаб-квартира :",
        about:"Про нас :",
        abouttext:"Офіційно ліцензовані та уповноважені Міністерством Охорони Здоров'я та Міністерством Культури та Туризму Турецької Республіки."

      },
  address:
        {
          addressdir: "Адреси :",
          locations:[
            {id:"1",country:"Туреччина :", location:"İçmeler Mah. Altunay Sk. No: 33, İç kapı No: 33. Tuzla, İstanbul, Türkiye."},
            {id:"2",country:"Азербайджан :", location:"В процесі."},
            {id:"3",country:"Узбекистан :", location:"В процесі."}
          ]
      }
}

export default con_ua;
