const head_data_az = {

  "form":
      {
        "head": "Sizi arayalım ?",
        "button": "Arayın !",
        "phname": "İsim soyisim",
        "phtel": "Telefon numaranız"
      },
  "head":
        {
        "slogan": "SİZİN SAĞLIĞINIZ BİZİM ÜÇÜN ÇOX ÖNƏMLİDİR",
        "slogantext": "Başdan sona bütün proses boyunca sizinləyik"
      },
 "social":
      {
        "first": "Sürətli əlaqə",
        "second": "İstənilən vaxt 24/7 əlaqə saxlaya bilərsiniz"
      }

}

export default head_data_az;
