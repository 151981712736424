const head_data_ru = {

  "form":
      {
        "head": "Позвонить вам ?",
        "button": "Звоните !",
        "phname": "Имя фамилия",
        "phtel": "Номер телефона"
      },
  "head":
        {
        "slogan": "ВАШЕ ЗДОРОВЬЕ ОЧЕНЬ ВАЖНО ДЛЯ НАС",
        "slogantext": "На протяжении всего процесса, от начала до конца, мы всегда рядом с вами."
      },
 "social":
     {
    "first": "Быстрая связь",
    "second": "Можете связаться с нами в любое время 24/7"
    }
}

export default head_data_ru;
