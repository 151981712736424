const ser_ua = [
  {id:"1",logo:"icons/heart.svg",name:"Серце", text:"Аритмії, судинні захворювання, ЕКГ, відкрита хірургія, ендоскопія, вроджені хвороби серця, порушення ритму та провідності, кардіологія та ін."},
  {id:"2",logo:"icons/tube_baby.svg",name:"ЕКО", text:"Збір яйцеклітин, перенесення ембріонів, підтримуюче лікування, хірургічні втручання, що підвищують ймовірність вагітності, детокс сперми, аплікації micro-tese pesa tesa та ін."},
  {id:"3",logo:"icons/inner.svg",name:"Внутрішні хвороби", text:"Гастроентерологія, гематологія, зменшення шлунка, ревматологія, медична онкологія, рак шлунка, ендокринологія, порушення обміну речовин та ін."},
  {id:"4",logo:"icons/nose.svg",name:"Естетика та Пластика", text:"Эстетическая хирургия, расщелина губы и неба, врожденные аномалии, операции по подтяжке живота и лица, хирургия половых органов, увеличение, уменьшение и подтяжка груди, травмы всего тела, носа и др."},
  {id:"5",logo:"icons/brain.svg",name:"Мозок", text:"Неврологія, нейрорадіологія, нейроанестезія, психіатрія, нейроендоскопія та лікування гідроцефалії, нейрохірургія, хвороби дисків та дегенеративні захворювання хребта, травми, хірургія периферичних нервів, дитяча нейрохірургія та ін."},
  {id:"6",logo:"icons/hand.svg",name:"Шкіра", text:"Акне, мікоз, афтозна виразка, ущільнення та потовщення, гонорея, хвороба бехчету, біхектомія, рак шкіри, проказа, родимка, екзема, клітинна терапія, гострі кондиломи, зморшки, бородавки та ін."},
  {id:"7",logo:"icons/liver.svg",name:"Печінка", text:"Гепатит (A, B, C, D, E), ожиріння печінки (алкогольне та незалежне), цироз, рак печінки, кісти печінки; проста кіста, ехінококова кіста, гемангіома та ін."},
  {id:"8",logo:"icons/stomach.svg",name:"Шлунок", text:"Рефлюкс (гастроезофагеальний рефлюкс), гастрит, виразка шлунка (виразка гастриту), рак шлунка, хелікобактер, операція зі зменшення шлунка, шлункова грижа та ін."},
  {id:"9",logo:"icons/tooth.svg",name:"Рот та зуби", text:"Щелепно-лицьова хірургія, ендодонтія, естетична стоматологія, оральна імплантологія, ортодонтія, протезування, пародонтохолі, педодонтія, блок скронево-нижньощелепного суглоба та ін."},
  {id:"10",logo:"icons/eye.svg",name:"Очі", text:"Глаукома, окулопластична хірургія, захворювання сітківки, рефракційна хірургія, рогівка та контактні лінзи, косоокість, амбліопія (ліниве око), лазерна терапія, катаракта та ін."},
  {id:"11",logo:"icons/compass.svg",name:"Трансплантація органів", text:"Нирки, печінка, серце, серцеві клапани, нерви, судини, легені, сухожилля, рогівка, шкіра, підшлункова залоза, тонкий кишечник, кістковий мозок, кістки та ін."},
  {id:"12",logo:"icons/microscope.svg",name:"Лабораторії", text:"Серологія, ендокринологія, молекулярна біологія, мікробіологія, клінічна хімія, гематологія, загальні аналізи, визначення результатів, обстеження хвороб та ін."},
  {id:"13",logo:"icons/hair.svg",name:"Пересадка волосся", text:"Трансплантація волосся - це природне і постійне вирішення проблеми витончення і облисіння у людей, які страждають на облисіння. Здійснюється шляхом трансплантації волосяних фолікулів мікрохірургічними методами."},
  {id:"14",logo:"icons/ruler.svg",name:"Збільшення зростання", text:"Це ортопедична хірургічна процедура для збільшення довжини ніг через проблеми зі здоров'ям або з естетичних причин. Це операція, яка застосовується не лише до ніг, а й до рук."},
  {id:"15",logo:"icons/gynecologist.svg",name:"Гінекологія та Онкологія", text:"Акушерство, гінекологічні захворювання, менопауза, міома, полікістоз яєчників, рак шийки матки, рак ендометрію, пухлини прямої кишки, рак яєчників та ін."},
  {id:"16",logo:"icons/bone.svg",name:"Кістки", text:"Хірургія пухлин кісток та м'яких тканин, трансплантація кісткового мозку (дорослий та дитячий), захворювання кісток, переломи, хірургічне втручання та ін."},
  {id:"17",logo:"icons/baby-trolley.svg",name:"Педіатрія та дитячі хвороби", text:"Алергія, хірургія, нейрохірургія, онкологія, гематологія, кардіологія, неврологія, нефрологія, інфекції, гастроентерологія, генетичні захворювання, інтенсивна терапія, психічне здоров'я, урологія, ревматологія та ін."},
  {id:"18",logo:"icons/lungs.svg",name:"Захворювання грудної клітки", text:"Астма, алергічні захворювання, бронхіт, хронічна обструктивна хвороба, легеневі захворювання, пневмонія, туберкульоз, тромбоемболія легеневої артерії, хронічний кашель, рак легенів та ін."},
  {id:"19",logo:"icons/gym.svg",name:"Фізіотерапія та реабілітація", text:"Фізіотерапія та реабілітація, це напрям у медицині, метою якої є підвищення до максимального рівня функцій тіла, відновлення працездатності, поліпшення якості життя людей, які втратили функціональність і рухливість тіла."},
  {id:"20",logo:"icons/operation.svg",name:"Хірургія", text:"Мозок і нерви, загальна хірургія, зуби, щелепа, кисть, естетична, пластична та реконструктивна, грудна, гастроентерологія та колопроктологія, серцево-судинна, роботизована хірургія, протези стопи та гомілковостопного суглоба, стегна та коліна та ін."},
  {id:"21",logo:"icons/stethoscope.svg",name:"Інші", text:"У нашому списку категорій представлені не всі зони медичних послуг, які надаються лікарнями. Зв'яжіться з нами, щоб дізнатися про існуючі послуги та рішення, яких немає у списку."}
]
export default ser_ua;
