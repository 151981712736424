const sp_az = [
  {id:"1",logo:"icons/medical-sign.svg",name:"İlkin prosedurlar", text:"Xaricdən bizimlə əlaqə saxladıqda sağlamlıq durumunuz, hesabat və analizlər barədə xəstəxanalara məlumat göndərir, həkimlərin ilkin diaqnozunu və təxmini qiymətlərini öyrənib sizinlə paylaşırıq. Biz yalnız sizin probleminiz üçün ən yaxşı həkimləri və xəstəxanaları seçir və gəlişiniz üçün bütün prosedurları təşkil edirik."},
  {id:"2",logo:"icons/doctor.svg",name:"Həkimlər", text:"Peşəkar həkimlər və yüzlərlə tibb işçisi sizin xidmətinizdədir. Sizin probleminiz üzrə mütəxəssiz olan ən yaxşı həkimləri seçir və onları sizinlə əlaqələndiririk."},
  {id:"3",logo:"icons/hospital2.svg",name:"Xəstəxanalar", text:"Biz Türkiyə Respublikasında müqavilə bağladığımız bütün beynəlxalq standartlara cavab verən xəstəxanaları birləşdiririk. Sizin istəyinizə əsasən müxtəlif səviyyəli xəstəxanalar seçə bilərik."},
  {id:"4",logo:"icons/ticket.svg",name:"Təyyarə bileti", text:"Biz sizə sərfəli qiymətlərlə və tərəfdaş aviaşirkətlərdən endirimlərlə Türkiyəyə və istənilən ölkədən geriyə aviabiletlər təklif edirik."},
  {id:"5",logo:"icons/van.svg",name:"Nəqliyyat", text:"Siz gəldikdən sonra biz sizi hava limanından VİP avtomobillərimizlə qarşılayacayıq və siz birbaşa xəstəxanaya və ya otelinizə aparacağiq, ölkənizə qayıdarkən isə sizi VİP avtomobillə hava limanına aparacağıq."},
  {id:"6",logo:"icons/flight.svg",name:"Təcili yardım təyyarəsi", text:"Çox təcili hallarda biz sizi özəl ambulansla istənilən ölkədən Türkiyəyə təyyarə ilə gətirəcəyik. Təyyarə ilə həkimlərdən ibarət özəl tibb qrupu da gələcək."},
  {id:"7",logo:"icons/ambulance.svg",name:"Təcili yardım", text:"Təcili hallarda biz xəstələri hava limanından təcili yardım maşını ilə şəxsən götürüb birbaşa xəstəxanaya çatdırırıq. Sağlamlığınızın hər dəqiqəsi bizim üçün önəmlidir."},
  {id:"8",logo:"icons/hotel.svg",name:"Otellər", text:"Biz sizin üçün güzəştli qiymətlərlə seçimlərinizə uyğun müxtəlif kateqoriyalı otellər təqdim edir və seçiminizə uyğun olaraq mualicə olunacağınız xəstəxanalara yaxın otellərdə yerləşdiririk."},
  {id:"9",logo:"icons/papers.svg",name:"Tərcüməçi", text:"Həkimlərlə ünsiyyətinizi asanlaşdıracaq və ana dilinizdən türk dilinə tərcümə edəcək peşəkar tərcüməçilər təklif edirik. Dil maneələrini aradan qaldırırıq."},
  {id:"10",logo:"icons/boy.svg",name:"Sənədlərin tərcüməsi", text:"Tibbi hesabatlarınızı, fayllarınızı və digər məlumat sənədlərinizi ana dilinizdən türk dilinə tərcümə edirik. Biz onları xəstəxanalara təqdim edir, düzgün diaqnozun qoyulması üçün vaxtın sürətləndirilməsinə kömək edirik."}
]
export default sp_az;
