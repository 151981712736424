import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Logo from '../components/logo';
import Head from '../components/head';
import Languages from '../components/languages';
import Serviceprocess from '../components/serviceprocess';
import Services from '../components/services';
import Doctors from '../components/doctors';
import Hospitals from '../components/hospitals';
import Social from '../components/social';
import Socialmobile from '../components/socialmobile';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Headfooter from '../components/headfooter';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_ru from '../jsons/services/s_ru';
import doc_ru from '../jsons/doctors/doc_ru';
import hos_ru from '../jsons/hospitals/h_ru';
import sp_ru from '../jsons/sprocess/sp_ru';
import head_data_ru from '../jsons/head/head_data_ru';
import con_ru from '../jsons/contacts/con_ru';


//

class Russian extends React.Component {

  render(){
    return (
          <div>
               <Route exact path="/ru" component={() => <Social />}/>
               <Route exact path="/ru" component={() => <Socialmobile />}/>
               <Route exact path="/ru" component={() => <Headfooter />}/>
               <Route exact path="/ru" component={() => <Languages lang="Русский" />} />
               <Route exact path="/ru" component={() => <Logo />}/>
               <Route exact path="/ru" component={() => <Head headdata={head_data_ru} />}/>
               <Route exact path="/ru" component={() => <Serviceprocess sprocess={sp_ru} dirname="Наши услуги"/>}/>
               <Route exact path="/ru" component={() => <Services langdata={ser_ru} dirname="Области медицинских услуг"/>} />
               <Route exact path="/ru" component={() => <Doctors langdata={doc_ru} dirname="Doxtorlar" />} />
               <Route exact path="/ru" component={() => <Hospitals langdata={hos_ru} />} />
               <Route exact path="/ru" component={() => <Contact langdata={con_ru}/>}  />
               <Route exact path="/ru" component={() => <Footer footerdata="Все права защищены" />} />

          </div>

    );
    }
}

export default Russian;
