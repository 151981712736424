const Services = (props) => {

  const ser = props.langdata;
  return (
    <div className="wavebottom">
    <div className="secondwavetop">
    <div className="container col-md-12 distaceservice">
      <p className="dirsection">{props.dirname}</p>
      <div className="row services">

                {ser.map(sss => (
                <div className="col-md-4 servicesection" key={sss.id}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3">
                      <img className="icons"  src={sss.logo}  alt=""/>
                      </div>
                      <div className="col-md-9">
                            <p className="service-name">{sss.name}</p>
                            <p className="service-desing">{sss.text}</p>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
              ))}

      </div>
      </div>
    </div></div>
  );
}

export default Services;
