import {Link} from "react-router-dom";

const NotFound =(props) => {
  return (
      <div className="col-md-8 offset-md-2 backerror">
        <Link to="/"><button type="button" className="langbut">Back</button></Link>
        <div className="error">404</div>
      </div>
  );
}
export default NotFound;
