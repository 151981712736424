const sp_ru = [
  {id:"1",logo:"icons/medical-sign.svg",name:"Предварительные процедуры", text:"Обращаясь к нам из-за границы, мы отправляем информацию о вашем состоянии здоровья, отчеты и анализы в больницы, узнаем предварительный диагноз врачей и приблизительные цены и передаем их вам. Подбираем для вас лучших докторов и больниц исключительно по вашей проблемы, и организовываем все процедуры к вашему приезду."},
  {id:"2",logo:"icons/doctor.svg",name:"Доктора", text:"К вашим услугам профессиональные доктора и сотни медицинских работников. Мы отбираем лучших врачей, которые являются специалистами именно в области вашей проблемы, и связываем их с вами."},
  {id:"3",logo:"icons/hospital2.svg",name:"Больницы", text:"Мы объединяем больницы Турецкой Республике с которыми у нас есть соглашение, отвечающие всем международным стандартам. Мы можем подобрать для вас больницы разного уровня по вашему запросу."},
  {id:"4",logo:"icons/ticket.svg",name:"Билет самолёта", text:"Мы предлагаем вам авиабилеты в Турцию и обратно из любой страны по доступным ценам и со скидкой от партнерских авиакомпаний."},
  {id:"5",logo:"icons/van.svg",name:"Транспортировка", text:"Во время вашего прибытия мы встретим вас в аэропорту на наших вип-автомобилях и доставим прямо в больницу или в ваш отель, и на обратном пути в вашу страну мы так же доставим вас в аэропорт на вип-автомобиле."},
  {id:"6",logo:"icons/flight.svg",name:"Самолёт скорой помощи", text:"В очень срочных случаях мы немедленно доставим вас из любой страны в Турцию на частном самолете скорой помощи. Вместе с самолетом также прибудет частная медицинская бригада, состоящая из врачей."},
  {id:"7",logo:"icons/ambulance.svg",name:"Машина скорой помощи", text:"В экстренных случаях мы лично встречаем пациентов из аэропорта на машине скорой помощи и доставляем их прямо в больницу. Для нас важна каждая минута вашего здоровья."},
  {id:"8",logo:"icons/hotel.svg",name:"Отели", text:"Мы подбираем для вас отели разных категорий подходящие к вашим предпочтениям по скидочным ценам, и заселяем вас в отели находящиеся неподалеку от вашей больницы."},
  {id:"9",logo:"icons/boy.svg",name:"Переводчик", text:"Мы предлагаем профессиональных переводчиков, которые упростят вам общение с врачами и выполнят перевод с вашего родного языка на Турецкий язык. Устраняем языковые барьеры."},
  {id:"10",logo:"icons/papers.svg",name:"Перевод документов", text:"Переводим ваши медицинские отчеты, файлы и другие информационные документы с вашего родного языка на Турецкий язык. Предоставляем их больницам и врачам, способствуем ускорению времени для определение правильного диагноза."}
]
export default sp_ru;
