import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Logo from '../components/logo';
import Head from '../components/head';
import Languages from '../components/languages';
import Serviceprocess from '../components/serviceprocess';
import Services from '../components/services';
import Doctors from '../components/doctors';
import Hospitals from '../components/hospitals';
import Social from '../components/social';
import Socialmobile from '../components/socialmobile';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Headfooter from '../components/headfooter';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_tr from '../jsons/services/s_tr';
import doc_tr from '../jsons/doctors/doc_tr';
import hos_tr from '../jsons/hospitals/h_tr';
import sp_tr from '../jsons/sprocess/sp_tr';
import head_data_tr from '../jsons/head/head_data_tr';
import con_tr from '../jsons/contacts/con_tr';

//

class Turkish extends React.Component {

  render(){
    return (
          <div>
               <Route exact path="/tr" component={() => <Social />}/>
               <Route exact path="/tr" component={() => <Socialmobile />}/>
               <Route exact path="/tr" component={() => <Headfooter />}/>
               <Route exact path="/tr" component={() => <Languages lang="Türkçe" />} />
               <Route exact path="/tr" component={() => <Logo />}/>
               <Route exact path="/tr" component={() => <Head headdata={head_data_tr} />}/>
               <Route exact path="/tr" component={() => <Serviceprocess sprocess={sp_tr} dirname="Hizmetlerimiz"/>}/>
               <Route exact path="/tr" component={() => <Services langdata={ser_tr} dirname="Sağlık hizmet alanları"/>} />
               <Route exact path="/tr" component={() => <Doctors langdata={doc_tr} dirname="Doxtorlar" />} />
               <Route exact path="/tr" component={() => <Hospitals langdata={hos_tr} />} />
               <Route exact path="/tr" component={() => <Contact langdata={con_tr}/>} />
               <Route exact path="/tr" component={() => <Footer footerdata="Tüm haklar saklıdır" />} />


          </div>

    );
    }
}

export default Turkish;
