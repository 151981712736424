const sp_en = [
  {id:"1",logo:"icons/medical-sign.svg",name:"Pre-treatments", text:"When contacting us from abroad, we send information about your health condition, reports and analyzes to hospitals, find out the preliminary diagnosis of doctors and approximate prices and pass them on to you. We select the best doctors and hospitals for you solely according to your problem, and organize all procedures for your arrival."},
  {id:"2",logo:"icons/doctor.svg",name:"Doctor", text:"Professional doctors and hundreds of medical professionals are at your service. We select the best doctors who are specialists in the specific area of your problem and put them in touch with you."},
  {id:"3",logo:"icons/hospital2.svg",name:"Hospital", text:"We unite hospitals in the Republic of Turkey with which we have an agreement, that meet all international standards. We can select hospitals of different levels for you at your request."},
  {id:"4",logo:"icons/ticket.svg",name:"Fly ticket", text:"We offer you air tickets to Turkey and back from any country at affordable prices and with a discount from partner airlines."},
  {id:"5",logo:"icons/van.svg",name:"Transportation", text:"Upon your arrival, we will meet you at the airport in our vip cars and take you directly to the hospital or your hotel, and on the way back to your country we will also take you to the airport in our vip car."},
  {id:"6",logo:"icons/flight.svg",name:"Ambulance plane", text:"In very urgent cases, we will immediately take you from any country to Turkey in a private ambulance plane. A private medical team of doctors will also arrive with the plane."},
  {id:"7",logo:"icons/ambulance.svg",name:"Ambulance", text:"In case of emergency, we personally meet patients from the airport by ambulance and deliver them directly to the hospital. Every minute of your health is important to us."},
  {id:"8",logo:"icons/hotel.svg",name:"Hotel", text:"We select for you from our partner hotels of different categories suitable for your preferences at discounted prices, and we check you in hotels located near your hospital."},
  {id:"9",logo:"icons/boy.svg",name:"Interpreter", text:"We offer professional translators who will make it easier for you to communicate with doctors and translate from your native language into Turkish at your request. We remove language barriers."},
  {id:"10",logo:"icons/papers.svg",name:"Translation of documents", text:"We translate your medical reports, files and other information documents from your native language into Turkish. We provide them to hospitals and doctors, helping to speed up the time to determine the correct diagnosis."},
]
export default sp_en;
