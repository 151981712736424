const doc_en = [
  {
    id:"0",
    dirname:"Doctors",
    endslogan:"And many others.",
    doctors:[
      {id:"0",logo:"images/doctors/1.jpg",name:"Prof. Halil İbrahim Bekler M.D.", text:"Microsurgery, Orthopedics"},
      {id:"1",logo:"images/doctors/8.jpg",name:"Prof. Metin Ekinci M.D.", text:"Eye Health and Diseases"},
      {id:"2",logo:"images/doctors/9.jpg",name:"Prof. Mehmet Yıldız M.D.", text:"Surgical"},
      {id:"3",logo:"images/doctors/12.jpg",name:"Prof. İbrahim Ethem Poyrazoğlu M.D.", text:"Otolaryngologist (ENT)"},
      {id:"4",logo:"images/doctors/13.jpg",name:"Assoc. Prof. Orkun Koban M.D.", text:"Brain and Nerve Surgery"},
      {id:"5",logo:"images/doctors/17.jpg",name:"Assoc. Prof. Muhammed Beşir Öztürk M.D.", text:"Plastic Surgery"},
      {id:"6",logo:"images/doctors/2.jpg",name:"Op. Osman Aktaş M.D.", text:"Surgical"},
      {id:"7",logo:"images/doctors/3.jpg",name:"Op. Makbule Laçinyurt Özkan M.D.", text:"Surgical"},
      {id:"8",logo:"images/doctors/4.jpg",name:"Op. Osman Fazlıoğulları M.D.", text:"Cardiovascular surgery"},
      {id:"9",logo:"images/doctors/18.jpg",name:"Op. Asude Köksal M.D.", text:"Plastic Surgery"},
      {id:"10",logo:"images/doctors/16.jpg",name:"Op. Aykut Mısırlıoğlu M.D.", text:"Plastic Surgery"},
      {id:"11",logo:"images/doctors/5.jpg",name:"Op. Mehmet Akif Göğüsgeren M.D.", text:"Neurosurgery"},
      {id:"12",logo:"images/doctors/6.jpg",name:"Op. Tülin Eroğlu Elmas M.D.", text:"Gynecology"},
      {id:"13",logo:"images/doctors/11.jpg",name:"Op. Ender Kavukçu M.D.", text:"Urology"},
      {id:"14",logo:"images/doctors/7.jpg",name:"Spec. Hakkı Özaslan M.D.", text:"Radiology"},
      {id:"15",logo:"images/doctors/14.jpg",name:"Spec. Vusal Khankıshıyev M.D.", text:"Cardiology"},
      {id:"16",logo:"images/doctors/10.jpg",name:"Spec. Yusuf Dikici M.D.", text:"Neurology"},
      {id:"17",logo:"images/doctors/15.jpg",name:"Dt. Deha Bayrak", text:"Oral and Dental Health"} ]
}
]
export default doc_en;
