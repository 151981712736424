const Hospitals =(props) => {

  const hos = props.langdata;

  return (
    <div className="secondwavebottom">
    <div className="container col-md-12 distancehospital">
    <p className="dirsection">{hos[0].dirname}</p>
      <div className="row services">

      {hos.map(hospitals_en => (
        <div className="col-md-12 row">

              {hospitals_en.hospitals.map(hospi => (
                <div className="col-md-3 servicesection hosspace" key={hospi.id}>
                  <div className="container hospitalsback col d-flex align-items-center">
                        <img className="hicons" src={hospi.logo}  alt=""/>
                  </div>
                </div>
              ))}

        </div>
          ))}

          <p className="and_other_hospital">{hos[0].endslogan}</p>

      </div>
  </div>
    </div>
  );
}

export default Hospitals;
