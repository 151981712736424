const head_data_en = {

  "form":
      {
        "head": "Let us call you",
        "button": "Call me !",
        "phname": "Name surname",
        "phtel": "Phone number"
      },
  "head":
        {
        "slogan": "YOUR HEALTH IS VERY IMPORTANT FOR US",
        "slogantext": "Throughout the entire process, from start to finish, we will always be near you."
      },
 "social":
       {
         "first": "Fast communication",
         "second": "You can contact us anytime 24/7"
       }

}

export default head_data_en;
