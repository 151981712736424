const Doctors =(props) => {

  const doc = props.langdata;

  return (
    <div className="doctorsbg">
    <div className="container col-md-12 services">
    <p className="dirsectiondr">{doc[0].dirname}</p>


      {doc.map(doctors_json => (
        <div className="col-md-12 row">

              {doctors_json.doctors.map(doc_info => (
                <div className="col-md-2 servicesection doctor_div_space" key={doc_info.id}>
                  <div className="container docktorsback col justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                          <img className="doctor_photo" src={doc_info.logo}  alt=""/>
                        </div>
                        <div className="col-md-12">
                              <p className="service-name texthospital">{doc_info.name}</p>
                              <p className="service-desing texthospital">{doc_info.text}</p>
                        </div>
                    </div>
                  </div>
              </div>
              ))}

              </div>
          ))}

              <p className="and_other_doctor">{doc[0].endslogan}</p>


      </div>
  </div>
  );
}

export default Doctors;
