const Socialmobile =(props) => {

  function gototel() {
    window.location.href = 'https://t.me/acilyolcu';
  }

  function gotowp() {
    window.location.href = 'https://wa.me/905523660088';
  }

  function gocall() {
    window.open('tel:00908508000082');
  }

  function gomail() {
    window.location = "mailto:info@acilyolcu.com";
  }

  return (
    <div className="socialmobilestatus">
      <div className="col-md-12">
      <div class="row">
        <div className="socialmobile">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <img className="socialmobileicon" onClick={gocall} src="icons/social/call.svg" alt=""/>
                  </div>
                  <div class="col">
                      <img className="socialmobileicon" onClick={gototel} src="icons/social/telegram.svg" alt=""/>
                  </div>
                  <div class="col">
                    <img className="socialmobileicon" onClick={gotowp} src="icons/social/whatsapp.svg" alt=""/>
                  </div>
                  <div class="col">
                    <img className="socialmobileicon" onClick={gomail} src="icons/social/envelope.svg" alt=""/>
                  </div>
                </div>
            </div>
          </div>
          </div>
      </div>
  </div>
  );
}
export default Socialmobile;
