const ser_tr = [
  {id:"1",logo:"icons/heart.svg",name:"Kalp", text:"Aritmiler, damar hastalıkları, EKG, açık cerrahi, endoskopi, doğumsal kalp hastalıkları, ritim ve ileti bozukluğu, kardiyoloji v.d. "},
  {id:"2",logo:"icons/tube_baby.svg",name:"Tüp bebek", text:"Yumurta toplama, embriyo transferi, destekleyici tedavi, gebelik şansını artıran cerrahi uygulamalar, sperm detoksu, mikro tese pesa tesa uygulamaları v.d."},
  {id:"3",logo:"icons/inner.svg",name:"İç hastalıkları", text:"Gastroenteroloji, hematoloji, mide küçültme, romatoloji, tibbi onkoloji, mide kanseri, endokrinoloji, metabolizma hastalıkları v.d. "},
  {id:"4",logo:"icons/nose.svg",name:"Estetik ve Plastik", text:"Estetik cerrahi, dudak-damak yarıkları, konjenital anomaliler, karın ve yüz germe ameliyatları, genital bölge cerrahisi, meme büyültme küçültme ve dikleştirme, tüm vücüt yaralanmaları, burun v.d."},
  {id:"5",logo:"icons/brain.svg",name:"Beyin", text:"Nöroloji, nöroradyoloji, nöroanestezi, psikiyatri, nöroendoskopi ve hidrosefali tedavisi, beyin pili, disk hastalıkları ve dejeneratif omurga hastalıkları, travmalar, periferik sinir cerrahisi, pediatrik nöroşirurji v.d."},
  {id:"6",logo:"icons/hand.svg",name:"Deri", text:"Akne, ayak mantarı, aftöz ülser, sertleşme ve kalınlaşma, bel soğokluğu, behçet, bişektomi, cilt(deri) kanseri, cüzzzam, doğum lekesi, egzama, hecresel tedavi, genital siğiller, kırışlıklar, siğiller v.d."},
  {id:"7",logo:"icons/liver.svg",name:"Karaciğer", text:"Hepatit (A,B,C,D,E), karaciğer yağlanması (alkole bağlı ve bağımsız), siroz, karaciğer kanseri, karaciğer kistleri; basit kist, kist hidatik, hemanjiom, v.d."},
  {id:"8",logo:"icons/stomach.svg",name:"Mide", text:"Reflü (gastroözofageal reflü), gastrit, mide ülseri (gastrit ülser),  mide kanseri, helikobakter, mide küçültme cerrahi ameliyatı, ülser, mide fıtığı v.d."},
  {id:"9",logo:"icons/tooth.svg",name:"Ağız ve Diş", text:"Ağız, diş ve çene hastalıkları cerrahisi, endodonti, esetikk diş hekimliği, oral implantoloji, ortodonti, protez, periodontojoli, pedodonti, temporomandibular eklem birimi v.d."},
  {id:"10",logo:"icons/eye.svg",name:"Göz", text:"Glokom, oküloplastik cerrahi, retina hastalıkları, refraktif cerrahi, kornea ve kontakt lens, şaşlık, göz tembelliği, lazer tedavisi, katarakt v.d."},
  {id:"11",logo:"icons/compass.svg",name:"Organ nakli", text:"Böbrek, karaciğer, kalp, kalp kapakçıkları, sinirler, damarlar, akciğer, tendonlar, kornea, deri, pankreas, ince bağırsak ile kemik iliği, kemik v.d."},
  {id:"12",logo:"icons/microscope.svg",name:"Laboratuvar", text:"Seroloji, endokrinoloji, moleküler biyoloji, mikrobiyoloji, klinik kimya, hematoloji, genel testler, sonuçların belirlenmesi, hastalıkların incelenmesi v.d."},
  {id:"13",logo:"icons/hair.svg",name:"Saç ekimi", text:"Saç ekimi, saç dökülmesi kaybı yaşayan kişilerde seyrelme ve kellik sorununa doğal ve kalıcı bir çözüm yoludur. Saç köklerinin mikro cerrahi yöntemlerle nakledilmesi işlemini ile gerçekleştirilmektedir."},
  {id:"14",logo:"icons/ruler.svg",name:"Boy uzatma", text:"Sağlık sorunları ya da estetik nedenlerle bacak uzunluğunu artırmak için yapılan ortopedik bir cerrahi işlemdir. Sadece bacaklara değil kollara da uygulanabilen bir ameliyattır."},
  {id:"15",logo:"icons/gynecologist.svg",name:"Jinekologi ve Onkoloji", text:"Doğum, kadın hastalıkları, menopoz, miyom, polikistik over, rahim ağzı kanser, endometrial kanser, rektorektal kitleler, yumurtalık kanseri v.d."},
  {id:"16",logo:"icons/bone.svg",name:"Kemik", text:"Kemik ve yumuşak doku tümörleri cerrahisi, kemik iliği nakli (erişkin ve pediyatrik), genel kemik hastalıkları, kırık, cerrahi müdahile v.d."},
  {id:"17",logo:"icons/baby-trolley.svg",name:"Çocuk hastalıkları", text:"Alerji, cerrahi, nöroşirürjisi, onkoloji, hematoloji, kardiyoloji, nöroloji, nefroloji, enfeksiyon, gastroenteroloji, genetik hastalıklar, yoğun bakım, ruh sağlığı, ürolokji, romatoloji v.d."},
  {id:"18",logo:"icons/lungs.svg",name:"Göğüs hastalıkları", text:"Astım, alerjik hastalıklar, bronşit, kronik obstrüktif, akçiğer hastalığı, zatürre, tüberküloz, akciğer embolisi, kronik öksürük, akciğer kanseri v.d."},
  {id:"19",logo:"icons/gym.svg",name:"Fizik tedavi ve Rehabilitasyon", text:"Fizik Tedavi ve Rehabilitasyon Bölümü, medikal teknolojik cihazlarla, kişiye odaklı ve bilimsel verilerle çalışan ekip tarafından hizmet sunmaktadır."},
  {id:"20",logo:"icons/operation.svg",name:"Cerrahi", text:"Beyin ve sinir, genel cerrahi, ağız diş ve çene, el, estetik, plastik ve rekonstrüktif, gögüs, gastroenteroloji ve koloproktoloji, kardiuovasküler, robotik cerrahi, ayak ve ayak beleği, kalça ve diz protezi v.d."},
  {id:"21",logo:"icons/stethoscope.svg",name:"Diğer", text:"Sunulan kategori listemizde hastanelerin sunduğu hizmet alanların tümü gösterilmemiştir. Listede bulunmayan fakat var olan hizmetler ve çözümler için bizimle lütfen iletişime geciniz."}
]
export default ser_tr;
