const doc_az = [
  {
    id:"0",
    dirname:"Həkimlər",
    endslogan:"Və digər dəyərli həkimlərimizdə sizlərlədir.",
    doctors:[
      {id:"0",logo:"images/doctors/1.jpg",name:"Prof. Dr. Halil İbrahim Bekler", text:"Əl və Mikrocərrahiyyə, Ortopediya."},
      {id:"1",logo:"images/doctors/8.jpg",name:"Prof. Dr. Metin Ekinci", text:"Göz Sağlamlığı və Xəstəlikləri"},
      {id:"2",logo:"images/doctors/9.jpg",name:"Prof. Dr. Mehmet Yıldız", text:"Cərrahi"},
      {id:"3",logo:"images/doctors/12.jpg",name:"Prof. Dr. İbrahim Ethem Poyrazoğlu", text:"QBB"},
      {id:"4",logo:"images/doctors/13.jpg",name:"Doç. Dr. Orkun Koban", text:"Beyin və Sinir Cərrahiyyəsi"},
      {id:"5",logo:"images/doctors/17.jpg",name:"Doç. Dr. Muhammed Beşir Öztürk", text:"Plastik cərrahiyyə"},
      {id:"6",logo:"images/doctors/2.jpg",name:"Op. Dr. Osman Aktaş", text:"Cərrahi"},
      {id:"7",logo:"images/doctors/3.jpg",name:"Op. Dr. Makbule Laçinyurt Özkan", text:"Cərrahi"},
      {id:"8",logo:"images/doctors/4.jpg",name:"Op. Dr. Osman Fazlıoğulları", text:"Ürək və Damar Cərrahiyyəsi"},
      {id:"9",logo:"images/doctors/18.jpg",name:"Op. Dr. Asude Köksal", text:"Plastik cərrahiyyə"},
      {id:"10",logo:"images/doctors/16.jpg",name:"Op. Dr. Aykut Mısırlıoğlu", text:"Plastik cərrahiyyə"},
      {id:"11",logo:"images/doctors/5.jpg",name:"Op. Dr. Mehmet Akif Göğüsgeren", text:"Neyrocərrahiyyə"},
      {id:"12",logo:"images/doctors/6.jpg",name:"Op. Dr. Tülin Eroğlu Elmas", text:"Ginekologiya"},
      {id:"13",logo:"images/doctors/11.jpg",name:"Op. Dr. Ender Kavukçu", text:"Urologiya"},
      {id:"14",logo:"images/doctors/7.jpg",name:"Uzm. Dr. Hakkı Özaslan", text:"Radiologiya"},
      {id:"15",logo:"images/doctors/14.jpg",name:"Uzm. Dr Vusal Khankıshıyev", text:"Kardiologiya"},
      {id:"16",logo:"images/doctors/10.jpg",name:"Uzm. Dr. Yusuf Dikici", text:"Nevrologiya"},
      {id:"17",logo:"images/doctors/15.jpg",name:"Dt. Deha Bayrak", text:"Ağız və diş sağlamlığı"} ]
}
]
export default doc_az;
