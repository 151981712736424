const doc_ru = [
  {
    id:"0",
    dirname:"Врачи",
    endslogan:"И многие другие.",
    doctors:[
      {id:"0",logo:"images/doctors/1.jpg",name:"Проф. Д-р. Халиль Ибрагим Беклер", text:"Микрохирургия, Ортопедия."},
      {id:"1",logo:"images/doctors/8.jpg",name:"Проф. Д-р. Метин Экинджи", text:"Здоровье и болезни глаз."},
      {id:"2",logo:"images/doctors/9.jpg",name:"Проф. Д-р. Мехмет Йылдыз", text:"Хирургия"},
      {id:"3",logo:"images/doctors/12.jpg",name:"Проф. Д-р. Ибрагим Этем Пойразоглу", text:"Отоларинголог (ЛОР)"},
      {id:"4",logo:"images/doctors/13.jpg",name:"Доц. Д-р. Оркун Кобан", text:"Хирургия мозга и нервов"},
      {id:"5",logo:"images/doctors/17.jpg",name:"Доц. Д-р. Мухаммед Бешир Озтюрк", text:"Пластическая хирургия"},
      {id:"6",logo:"images/doctors/2.jpg",name:"Оп. Д-р. Осман Акташ", text:"Хирургия"},
      {id:"7",logo:"images/doctors/3.jpg",name:"Оп. Д-р. Макбуле Лачинюрт Озкан", text:"Хирургия"},
      {id:"8",logo:"images/doctors/4.jpg",name:"Оп. Д-р. Осман Фазлыогуллары", text:"Сердечно-сосудистая хирургия"},
      {id:"9",logo:"images/doctors/18.jpg",name:"Оп. Д-р. Асуде Кёксал", text:"Пластическая хирургия"},
      {id:"10",logo:"images/doctors/16.jpg",name:"Оп. Д-р. Айкут Мысырлыоглу", text:"Пластическая хирургия"},
      {id:"11",logo:"images/doctors/5.jpg",name:"Оп. Д-р. Мехмет Акиф Гёхюсгерен", text:"Нейрохирургия"},
      {id:"12",logo:"images/doctors/6.jpg",name:"Оп. Д-р. Тюлин Эроглу Элмас", text:"Гинекология"},
      {id:"13",logo:"images/doctors/11.jpg",name:"Оп. Д-р. Эндер Кавукчу", text:"Урология"},
      {id:"14",logo:"images/doctors/7.jpg",name:"Спец. Д-р. Хакки Озаслан", text:"Радиология"},
      {id:"15",logo:"images/doctors/14.jpg",name:"Спец. Д-р Вусал Ханкишиев", text:"Кардиология"},
      {id:"16",logo:"images/doctors/10.jpg",name:"Спец. Д-р. Юсуф Дикичи", text:"Неврология"},
      {id:"17",logo:"images/doctors/15.jpg",name:"Дт. Деха Байрак", text:"Стоматология"} ]
}
]
export default doc_ru;
